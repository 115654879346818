
































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import {
  Postlevel2,
  PostExportSaveTable,
  GetFilterOption,
  GetTypesOption,
} from "@/request/differences";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
@Component({
  components: {
    MainHeader,
    NavLeft,
  },
})
export default class Name extends Vue {
  private laiyuans: any = [];
  private typeOptions: any = [];
  private firstFilter: any = [];
  private secondFilter: any = [];
  private filters: any = {
    name: "",
    source: [],
    采集时间: "近30天",
    startTime: null,
    endTime: null,
    高级筛选: [
      {
        type: "", // 和，或
        first: "", // 第一列数据
        data: {}, // 后面的数据
        level1Options: [], // 一级选项
        options: [], // 二级选项
      },
    ],
  };
  private list: any = [];
  private disabledStartDate: any = this.beginDate();
  private disabledEndDate: any = this.endDate();
  private beginDate() {
    const self = this;
    return {
      disabledDate(time: any) {
        if (self.filters.endTime) {
          let yearTime: any = new Date(self.filters.endTime);
          yearTime.setFullYear(yearTime.getFullYear() - 1);
          yearTime = new Date(yearTime).getTime() - 86400000;

          return (
            time.getTime() > new Date(self.filters.endTime).getTime() ||
            time.getTime() > Date.now() ||
            time.getTime() < new Date("2015-12-31").getTime() ||
            time.getTime() < yearTime
          );
        } else {
          return (
            time.getTime() > Date.now() ||
            time.getTime() < new Date("2015-12-31").getTime()
          );
        }
      },
    };
  }
  private endDate() {
    const self = this;
    return {
      disabledDate(time: any) {
        if (self.filters.startTime) {
          let yearTime: any = new Date(self.filters.startTime);
          yearTime.setFullYear(yearTime.getFullYear() + 1);
          yearTime = new Date(yearTime).getTime() - 86400000;
          return (
            new Date(self.filters.startTime).getTime() > time.getTime() ||
            time.getTime() > Date.now() ||
            time.getTime() < new Date("2015-12-31").getTime() ||
            time.getTime() > yearTime
          );
        } else {
          return (
            time.getTime() > Date.now() ||
            time.getTime() < new Date("2015-12-31").getTime()
          );
        }
      },
    };
  }
  // 采集时间修改
  private timeChange() {
    this.filters.startTime = null;
    this.filters.endTime = null;
  }
  // 删除一个筛选条件
  private deleteOne(i: any) {
    this.filters["高级筛选"].splice(i, 1);
  }
  // 添加一个筛选条件
  private addOne() {
    const obj = {
      type: "和", // 和，或
      first: "", // 第一列数据
      data: {}, // 后面的数据
      level1Options: [], // 一级选项
      options: [],
    };
    this.filters["高级筛选"].push(obj);
  }
  private getFirstFilter(i: any) {
    this.filters["高级筛选"][i]["level1Options"] = ["基本信息"];
    this.typeOptions.forEach((ele: any) => {
      if (ele.choice) {
        this.filters["高级筛选"][i]["level1Options"].push(ele["名称"]);
      }
    });
  }
  private focus(i: any) {
    this.getFirstFilter(i);
  }
  private getSecondFilter(item: any, i: any) {
    this.filters["高级筛选"][i].data = {};
    const params: any = {
      first: item,
    };
    Postlevel2(this, params).then((res: any) => {
      this.filters["高级筛选"][i].options = res;
    });
  }
  private sourceFocus(e: any) {
    if (!e) {
      this.getLaiyuanList("");
    }
  }
  private getLaiyuanList(val: any) {
    const params: any = {
      type: "数据来源",
      search: val,
    };
    GetFilterOption(this, params).then((res: any) => {
      // 如果有已经选的，并且不在当前列表中，需要添加进去
      if (this.filters.source.length > 0) {
        this.filters.source.forEach((ele: any) => {
          const index = res.findIndex((value: any) => {
            return ele._id === value._id;
          });
          if (index !== -1) {
            res.splice(index, 1);
          }
        });
        this.laiyuans = this.filters.source.concat(res);
      } else {
        this.laiyuans = res;
      }
    });
  }
  private getTypesList() {
    GetTypesOption(this).then((res: any) => {
      this.typeOptions = res;
    });
  }
  private changeLeixing(item: any) {
    item.choice = !item.choice;
    // 判断已选条件中是否有非第一级列表的，如果有需要删除
    this.filters["高级筛选"].forEach((ele: any, i: any) => {
      this.typeOptions.forEach((e: any) => {
        if (!e.choice && e["名称"] === ele.first) {
          // 如果是第一行清除数据，否则删除行
          if (i === 0) {
            const obj = {
              type: "和", // 和，或
              first: "", // 第一列数据
              data: {}, // 后面的数据
              level1Options: [], // 一级选项
              options: [],
            };
            this.$set(this.filters["高级筛选"], 0, obj);
          } else {
            this.deleteOne(i);
          }
        }
      });
    });
  }
  /**
   * @description 重置
   */
  private reset() {
    this.filters["采集时间"] = "近30天";
    this.filters.startTime = null;
    this.filters.endTime = null;
    this.filters.name = "";
    this.filters.source = [];
    this.filters["高级筛选"] = [
      {
        type: "", // 和，或
        first: "", // 第一列数据
        data: {}, // 后面的数据
        level1Options: [], // 一级选项
        options: [], // 二级选项
      },
    ];
    // this.typeOptions.forEach((ele, i) => {
    //   this.$set(this.typeOptions[i], "choice", false);
    // });
    this.getTypesList();
  }
  private makeSure() {
    if (!this.filters.name) {
      this.$message.warning("数据表格名称必填");
      return;
    }
    if (this.filters.name.length > 30) {
      this.$message.warning("数据表格名称不能超过30个字");
      return;
    }
    if (this.filters.source.length === 0) {
      this.$message.warning("数据来源至少选择一个");
      return;
    }
    const arr: any = [];
    this.typeOptions.forEach((ele: any) => {
      if (ele.choice) {
        arr.push(ele);
      }
    });
    if (arr.length === 0) {
      this.$message.warning("需要导出的数据至少选择一个");
      return;
    }
    if (this.filters["采集时间"] === "自定义") {
      if (!this.filters.startTime || !this.filters.endTime) {
        this.$message.warning("开始时间和结束时间必填！");
        return;
      }
    }
    // 查询指标参数
    // type一级类别字段的值，title二级val字段,
    // 拼接参数，查询指标
    const zhibaoArr: any = [];
    this.filters["高级筛选"].forEach((element: any) => {
      if (element["data"]["value"]) {
        zhibaoArr.push({
          // type: element["first"]["类别"],
          title: element["data"]["val"],
          value: element["data"]["value"],
          condition: element["data"]["condition"],
          key: element["data"]["key"],
          special: element["data"]["special"],
          first: element["data"]["first"],
        });
      }
    });
    const params = {
      筛选条件: zhibaoArr,
      采集时间: this.filters["采集时间"],
      开始时间: this.filters.startTime,
      结束时间: this.filters.endTime,
      需要导出的数据: this.typeOptions,
      数据来源: this.filters.source,
      名称: this.filters.name,
    };
    const loading = this.$loading({
      text: "正在生成数据表格，需要等待一段时间，完成后您可至右上角下载中心查看并导出该数据表格。",
    });
    PostExportSaveTable(this, params, loading)
      .then((data: any) => {
        this.$message.success(
          "导出成功,您可至右上角下载中心查看并导出该数据表格。"
        );
      })
      .finally(() => {
        loading.close();
      });
  }
  private mounted() {
    this.getLaiyuanList("");
    this.getTypesList();
  }
}
